import React from "react"
import Section from "../section"
import ServiceCard from "./service-card"

const Services = () => {
  const cards = [
    {
      title: "Préstamos corporativos",
      desc: "Obtén hasta 4 veces el monto en tu cuenta.",
    },
    {
      title: "Préstamos Inicio de operaciones",
      desc: "Aumenta tu capital, compra maquinaria o lo que necesites.",
    },
    {
      title: "Asesoramiento",
      desc: "Capacitación y asistencia técnica para socios.",
    },
    {
      title: "Cartera de inversiones",
      desc: "Incentivamos el crecimiento y fortalecimiento de las MYPES peruanas.",
    },
    {
      title: "Intercambios comerciales",
      desc: "Fortalecemos tu red de contactos nacionales e internacionales.",
    },
  ]
  return (
    <Section bgcolor={"bg-gradient-to-t from-sky-50 to-white"}>
      <div
        id="services-frame"
        className="flex-col md:flex md:flex-row w-full max-w-5xl gap-8 md:gap-12 py-6"
      >
        <h1 className="text-center pb-8 md:text-start font-ubuntu font-medium text-2xl">
          Servicios
        </h1>
        <div className="justify-center gap-8 flex md:justify-start flex-wrap md:gap-16">
          {cards.map((item, index) => (
            <ServiceCard title={item.title} desc={item.desc} />
          ))}
        </div>
      </div>
    </Section>
  )
}

export default Services
