import React from "react"
import Section from "./section"
import imgGoal from "../assets/images/goals-vision.svg"

const Mision = () => {
  return (
    <Section bgcolor={"bg-white"}>
      <div className="flex flex-col pb-12 md:flex-row gap-16 justify-center items-center w-full max-w-5xl">
        <h1 className="text-2xl"> Nosotros</h1>

        <img className="w-2/3 md:w-1/3" src={imgGoal} alt="SVG as an image" />
        <div className="w-full md:w-1/2 flex flex-col gap-10 items-center">
          <h1 className="text-xl font-ubuntu text-left w-full">Misión</h1>
          <div className="font-ubuntu">
            Fomentar el crecimiento y desarrollo de las MYPES Peruanas a través
            de planes estratégicos, proyectos productivos, negocios innovadores
            y cartera de inversiones, que generen un crecimiento sostenido y
            sostenibilidad en el tiempo en este importante sector.
          </div>
          <h1 className="text-xl text-left w-full font-ubuntu">Visión</h1>
          <div className="font-ubuntu">
            Ser una institución líder, innovadora con el principio permanente de
            mejora continua y mejor calidad de atención a los socios de la
            cooperativa.
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Mision
