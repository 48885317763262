import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../components/header/header"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import Section from "../components/section"
import TopBanner from "../components/top-banner"
import Divisor from "../components/divisor"
import Hero from "../components/hero"
import Services from "../components/services/services"
import Mision from "../components/mision"
import Footer from "../components/footer"

function App() {
  return (
    <div className="flex flex-col bg-white">
      <TopBanner />
      <Header isHome />
      <Hero />
      <div id="first-divisor">
        <Divisor />
      </div>
      <Services />
      <Mision />
      <Footer />
    </div>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default App
