import * as React from "react"
import Section from "./section"
import imgHero from "../assets/images/hero-happy.svg"
import ScrollIntoView from "react-scroll-into-view"

const Hero = () => {
  return (
    <Section bgcolor="bg-gradient-to-t from-sky-50">
      <div className="flex flex-col-reverse md:flex-row gap-16 justify-center items-center w-full max-w-5xl">
        <img className="w-4/5 md:w-1/2" src={imgHero} alt="SVG as an image" />
        <div className="order-1 md:w-1/2 flex flex-col gap-6 items-center">
          <h1 className="text-6xl font-bold font-ubuntu text-left w-full">
            Cooperativa MYPERU
          </h1>
          <h2 className="text-3xl text-left w-full font-ubuntu">
            Tu socio para cumplir tus sueños
          </h2>
          <ScrollIntoView selector="#first-divisor" className="w-full">
            <button className="flex cursor-pointer justify-center bg-sky-400 py-2 px-4 w-full rounded-md shadow-lg hover:bg-sky-500 text-slate-900 font-bold text-lg">
              Conócenos
            </button>
          </ScrollIntoView>
        </div>
      </div>
    </Section>
  )
}

export default Hero
