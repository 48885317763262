import React from "react"

const ServiceCard = ({ title, desc }) => {
  return (
    <div className="flex flex-col rounded-lg shadow-md overflow-hidden w-96 max-w-full font-ubuntu">
      <div className="bg-blue-800 text-white px-8 py-4">{title}</div>
      <div className="bg-white px-8 py-4 h-24">{desc}</div>
    </div>
  )
}
export default ServiceCard
